import styled from 'styled-components';
import { LinkList as LinkListComponent } from '../linkList/linkList.component';
import { text, title } from '../../theme/typography';
import { colors } from '../../theme/styled';
import ArrowDownImage from '../../images/arrow-expand-down.svg';
import { media } from '../../theme/media';

const NAV_COLLAPSED_HEIGHT = 72;
const LIST_ITEM_HEIGHT = 60;

const getMaxHeight = (isSmallDevice, collapse, linksLength) => {
  if (!isSmallDevice && !collapse) {
    return '100%';
  }

  return collapse ?
    `${NAV_COLLAPSED_HEIGHT}px` : `${NAV_COLLAPSED_HEIGHT + LIST_ITEM_HEIGHT * linksLength}px`;
};

export const COLLAPSE_TIME = 400;

export const LinkList = styled(LinkListComponent)`
  padding-left: 25px;
  padding-right: 25px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: ${colors.grey};
  border-top: 1px solid ${colors.borderMenuItem};

  nav {
    width: 100%;
  }

  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  h2 {
    ${title};
    font-size: 16px;
    color: ${colors.white};
    margin-bottom: 8px;
    line-height: 1.25;
    letter-spacing: 0.9px;
    display: none;
  }

  li {
    height: ${LIST_ITEM_HEIGHT}px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid ${colors.borderMenuItem};
    visibility: ${(props) => props.isVisible ? 'visible' : 'hidden' };

    &:last-child {
      border-bottom: none;
    }
  }

  a {
    ${text};
    color: ${colors.white};
    font-size: 14px;
    width: 100%;
    display: block;

    &:focus,
    &:hover {
    outline: none;
      text-decoration: underline;
    }
  }
`;

export const Nav = styled.div`
  overflow: hidden;
  width: 100%;
  background-color: ${colors.darkGrey};
  transition: max-height ${COLLAPSE_TIME}ms ease-in-out;
  max-height: ${(props) => getMaxHeight(props.isSmallDevice, props.collapse, props.linksLength) };
  border-top: 1px solid ${colors.borderMenuItem};
`;

export const CollapsedTitle = styled.button`
  ${title};
  font-size: 14px;
  color: ${colors.white};
  line-height: 1.25;
  letter-spacing: 0.9px;
  height: 72px;
  align-items: center;
  width: 100%;
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;
  outline-offset: -3px;
  background-color: ${colors.darkGrey};
  border-radius: 0;
  border: none;

  ${media.tablet`
    padding-left: 40px;
    padding-right: 40px;
  `};

  &:after {
    content: "";
    display: block;
    width: 48px;
    height: 48px;
    background-image: url(${ArrowDownImage});
    background-size: cover;
    transform: rotate(${(props) => props.collapse ? 0 : '180deg'});
    transition: transform 0.2s ease-in-out;
    margin-right: -4px;
  }
`;
