import { Component } from 'react';
import PropTypes from 'prop-types';


export class RouterScrollToTop extends Component {
  static propTypes = {
    location: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      setTimeout(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
      });
    }
  }

  render = () => null;
}
