import { css } from 'styled-components';
import { fonts, colors, fontWeights } from './styled';

export const title = css`
  font-family: ${fonts.titleFont};
  font-weight: ${fontWeights.bold};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.17;
  letter-spacing: 1px;
  color: ${colors.darkGrey};
  text-transform: uppercase;
`;

export const text = css`
  font-family: ${fonts.mainFont};
  color: ${colors.darkGrey};
  font-weight: ${fontWeights.normal};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.67;
  letter-spacing: normal;
  font-size: 16px;
  
  p {
    color: inherit; 
  }
`;

export const featuredTitle = css`
  font-family: ${fonts.secondFont};
  font-weight: ${fontWeights.bold};
  color: ${colors.darkGrey};
  font-style: italic;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 16px;
`;
