import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import { RouterScrollToTop } from './routerScrollToTop';
import { RouterResetFocus } from './routerResetFocus';

import { Article } from '../../../layouts/article';
import { Author } from '../../../layouts/author';
import { ContentfulComponent } from '../../../layouts/contentfulComponent';
import { Page } from '../../../layouts/page';

import { ROUTES } from '../../../constants';

export const Router = () => {
  return (
    <React.Fragment>
      <RouterScrollToTop />
      <RouterResetFocus />
      <Routes>
        <Route exact path={ROUTES.component.previewUrl} element={<ContentfulComponent />} />
        <Route exact path={ROUTES.article.previewUrl} element={<Article />} />
        <Route exact path={ROUTES.article.url} element={<Article />} />
        <Route exact path={ROUTES.author.previewUrl} element={<Author />} />
        <Route exact path={ROUTES.author.url} element={<Author />} />
        <Route exact path={ROUTES.home.url} element={<Page />} />
        <Route exact path="/index.html" element={<Navigate replace to="/" />} />
        <Route exact path={ROUTES.page.previewUrl} element={<Page />} />
        <Route exact path={ROUTES.page.url} element={<Page />} />
        <Route path={ROUTES.page.splat} element={<Page />} />
        <Route element={<Page slug={ROUTES.notFound.url} />} />
      </Routes>
    </React.Fragment>
  );
};
