/**
 * Redux components for handling aspects of pages that apply to different page types. See also page.sagas
 * @module "page.redux"
 **/

import { createActions, createReducer } from 'reduxsauce';
import { combineReducers } from 'redux';
import { Record } from 'immutable';
import { reducer as articlePageReducer } from './articlePage/articlePage.redux';
import { reducer as authorPageReducer } from './authorPage/authorPage.redux';
import { reducer as contentPageReducer } from './contentPage/contentPage.redux';
import { reducer as componentPageReducer } from './componentPage/componentPage.redux';

export const { Types: PageTypes, Creators: PageActions } = createActions({
  // There was an error in fetching the data.
  fetchError: ['payload'],
  // There is or is not a hero.
  setHeroPresent: ['isHeroPresent'],
  // There is or is not a hero with a title.
  setHeroWithTitlePresent: ['isHeroWithTitlePresent'],
  // No page for the associated path could be located
  setNotFound: ['isNotFound'],
}, { prefix: 'PAGE_' });

const PageRecord = new Record({
  isHeroPresent: true,
  isHeroWithTitlePresent: false,
  isNotFound: false,
});

export const INITIAL_STATE = new PageRecord({});

const handleHeroChanged = (state, { isHeroPresent }) => state.set('isHeroPresent', isHeroPresent);

const handleHeroWithTitleChanged = (state, { isHeroWithTitlePresent }) =>
  state.set('isHeroWithTitlePresent', isHeroWithTitlePresent);

const handleNotFound = (state, { isNotFound }) => state.set('isNotFound', isNotFound);

const pageReducer = createReducer(INITIAL_STATE, {
  [PageTypes.SET_HERO_PRESENT]: handleHeroChanged,
  [PageTypes.SET_HERO_WITH_TITLE_PRESENT]: handleHeroWithTitleChanged,
  [PageTypes.SET_NOT_FOUND]: handleNotFound,
});

export const reducer = combineReducers({
  generic: pageReducer,
  articlePage: articlePageReducer,
  authorPage: authorPageReducer,
  contentPage: contentPageReducer,
  componentPage: componentPageReducer,
});
