import React from 'react';

const LazyIframeForm = React.lazy(() => import('./iframeForm.container'));

const SuspendedIframeForm = React.forwardRef((props, ref) => (
  <React.Suspense fallback={null}>
    <LazyIframeForm ref={ref} {...props} />
  </React.Suspense>
));

export default SuspendedIframeForm;
