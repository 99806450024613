import { css } from 'styled-components';
import {
  MOBILE, TABLET_PORTRAIT, TABLET_LANDSCAPE, DESKTOP, DESKTOP_FULL,
} from '../components/platformDetector/platformDetector.component';

export const sizes = {
  desktopFull: 1920,
  desktop: 1440,
  desktopSmall: 1280,
  tabletLarge: 1024,
  tablet: 768,
  mobile: 450,
};

export const getDevicePixelRatio = () => window.devicePixelRatio;

export const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  acc[`${label}Retina`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      ${css(...args)}
    }
  `;
  acc[`${label}Landscape`] = (...args) => css`
    @media (min-width: ${sizes[label]}px) and (orientation: landscape) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

const matchWidth = (relation, width) => {
  const mediaMatches = window.matchMedia && window.matchMedia(`(${relation}-width: ${width}px)`).matches;
  const fallbackMatches = relation === 'max' ? (window.innerWidth <= width) : (window.innerHeight >= width);
  return window.matchMedia ? mediaMatches : fallbackMatches;
};

export const isMobile = () => {
  return matchWidth('max', sizes.tablet - 1);
};

export const isTabletPortrait = () => {
  return matchWidth('min', sizes.tablet) && matchWidth('max', sizes.tabletLarge - 1);
};

export const isTabletLandscape = () => {
  return matchWidth('min', sizes.tabletLarge) && matchWidth('max', sizes.desktop - 1);
};

export const isTablet = () => {
  return matchWidth('min', sizes.tablet) && matchWidth('max', sizes.desktop - 1);
};

export const isDesktop = () => {
  return matchWidth('min', sizes.desktop) && matchWidth('max', sizes.desktopFull - 1);
};

export const isDesktopFull = () => {
  return matchWidth('min', sizes.desktopFull);
};

export const isSmallDevice = () => {
  return isMobile() || isTablet();
};

export const isLargeDevice = () => {
  return isDesktop() || isDesktopFull();
};

export const noTouchHover = (...args) => css`
  .no-touchevents &:hover {
    ${css(...args)};
  }
`;

export const isLandscape = () => window.innerWidth > window.innerHeight;

export const activeResolution = () => {
  if (isMobile()) { return MOBILE; }
  if (isTabletPortrait()) { return TABLET_PORTRAIT; }
  if (isTabletLandscape()) { return TABLET_LANDSCAPE; }
  if (isDesktop()) { return DESKTOP; }

  return DESKTOP_FULL;
};
