import styled from 'styled-components';
import { media } from '../../theme/media';
import { colors, fontWeights } from '../../theme/styled';
import { focusOutline } from '../../theme/mixins';
import { H2 } from '../../theme/layout';
import { BoxShadow } from '../boxShadow/boxShadow.component';
import { Button as ButtonStyle } from '../button/button.component';
import TickIconSrc from '../../images/icon-tick.svg';

export const Container = styled(BoxShadow)`
  margin-top: 50px;

  ${media.tablet`
    margin-top: 58px;
  `};

  ${media.tabletLarge`
    position: absolute;
    max-width: 417px;
    margin-top: 0;
    right: 42px;
    bottom: 32px;
  `};

  ${media.desktop`
    right: 70px;
    bottom: 144px;
  `};

  ${media.desktopFull`
    right: 200px;
    bottom: 226.5px;
  `};

  &:before {
    content: '';
    z-index: 1;
    width: 100%;
    height: 4px;
    background-color: ${colors.red};
  }
`;

export const Form = styled.form`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 28px;
  padding-right: 28px;
  padding-top: 37px;
  padding-bottom: 100px;

  ${media.tablet`
    flex-direction: row;
    padding-left: 58px;
    padding-right: 58px;
    padding-top: 42px;
    padding-bottom: 72px;
    justify-content: space-between;
    align-items: center;
  `};

  ${media.tabletLarge`
    flex-direction: column;
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 100px;
    align-items: flex-start;
    justify-content: none;
  `};
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${media.tablet`
    margin-right: 41px;
  `};

  ${media.tabletLarge`
    margin-right: 0;
    max-width: 318px;
  `};
`;

export const Title = styled(H2)`
  color: ${colors.white};
  font-size: 22px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
`;

export const Description = styled.p`
  margin-top: 8px;
  margin-bottom: 18px;
  font-size: 14px;
  line-height: 1.57;
  color: ${colors.white};

  ${media.tablet`
    margin-top: 12px;
    margin-bottom: 0;
  `}

  ${media.tabletLarge`
    margin-bottom: 24px;
  `}
`;

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const Prefix = styled.span`
  position: absolute;
  font-size: 24px;
  font-weight: ${fontWeights.semiBold};
  left: 27px;
  top: 10px;

  ${media.tablet`
    left: 15px;
  `};
`;

export const Input = styled.input`
  padding-left: 45px;
  padding-right: 10px;
  line-height: 1.4;
  width: 50%;
  height: 80px;
  border: 0;
  border-radius: 0;
  font-size: 56px;
  font-weight: ${fontWeights.light};
  color: ${colors.darkGrey};
  -moz-appearance: textfield;


  ${media.tablet`
    padding-left: 30px;
    width: 160px;
  `};

  &::placeholder {
    color: ${colors.placeholder}
  }
`;

export const Button = styled(ButtonStyle)`
  min-width: auto;
  width: 50%;
  height: 80px;
  line-height: 80px;
  letter-spacing: 1px;
  font-size: 16px;

  ${media.tablet`
    width: 180px;
  `}
`;

export const CheckboxContainer = styled.div`
  position: absolute;
  bottom: -34px;
  display: flex;
  flex-direction: row;

  ${media.tablet`
    bottom: -23px;
  `};

  ${media.tabletLarge`
    bottom: -32px;
  `};
`;

export const Label = styled.label`
  position: absolute;
  padding-left: 32px;
  cursor: pointer;
  color: ${colors.white};
  font-size: 16px;
  line-height: 1;
  outline: none;

  &::selection {
    background-color: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 20px;
    height: 20px;
    border: 1px solid ${colors.copyright};
    background-color: transparent;
    transition: background-color .2s, border-color .2s;
  }

  &:after {
    content: '';
    position: absolute;
    background-image: url(${TickIconSrc});
    background-repeat: no-repeat;
    top: 4px;
    left: 5px;
    width: 10px;
    height: 8px;
    transition: opacity .2s;
  }
`;

export const Checkbox = styled.input`
  position: absolute;
  padding-left: 32px;
  margin-bottom: 48px;
  opacity: 0;

  &:focus + ${Label}:before {
    ${focusOutline};
  }

  &:checked + ${Label}:before {
    border-color: ${colors.accessibilityRed};
    background-color: ${colors.accessibilityRed};
  }

  &:not(:checked) + ${Label}:after {
    opacity: 0;
    transform: scale(0);
  }

  &:checked + ${Label}:after {
    opacity: 1;
    transform: scale(1);
  }
`;
