import styled from 'styled-components';
import { media, sizes } from '../../theme/media';
import { colors } from '../../theme/styled';
import { Section as SectionStyled } from '../section/section.component';
import { LineClip as LineStyle } from '../lineClip/lineClip.component';
import { LineDecoration as LineDecorationStyle } from '../lineDecoration/lineDecoration.component';
import { text } from '../../theme/typography';
import { Image as ResponsiveImage } from '../image/image.component';
import { Line } from '../lineDecoration/lineDecoration.styles';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 1100px;
  height: auto;
  overflow: hidden;
  
  ${media.tablet`
    min-height: 740px;
  `};
  
  ${media.tabletLarge`
    min-height: 770px;
  `};
  
  ${media.desktop`
    min-height: 1098px;
  `};
  
  ${media.desktopFull`
    min-height: 1464px;
  `};
`;

export const HeroImageContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  max-height: 1100px;
  
  ${media.tablet`
    max-height: 740px;
  `};
  
  ${media.tabletLarge`
    max-height: none;
  `};
  
  ${media.desktopFull`
    max-height: 1464px;
  `};
`;

export const LineClip = styled(LineStyle)`
  border-bottom-width: 75px;
  
  ${media.tablet`
    border-bottom-width: 170px;
  `};

  ${media.tabletLarge`
    border-bottom-width: 198px;
  `};

  ${media.desktop`
    border-bottom-width: 250px;
  `};

  ${media.desktopFull`
    border-bottom-width: 367px;
  `};
`;

export const Section = styled(SectionStyled)`
  position: relative;
  padding-top: 464px;
  padding-bottom: 90px;
  z-index: 0;

  ${media.tablet`
    padding-top: 109px;
    padding-bottom: 0;
  `};

  ${media.desktop`
    padding-top: 305px;
    padding-left: 70px;
    padding-right: 70px;
  `};

  ${media.desktopFull`
    padding-top: 408px;
  `};
`;

export const TITLE_IMAGE_SIZES = {
  [sizes.mobile]: 360,
  [sizes.tablet]: 460,
  [sizes.tabletLarge]: 460,
  [sizes.desktop]: 460,
};

export const TitleImage = styled(ResponsiveImage)`
  max-width: 460px;
  min-width: 320px;
  width: 100%;
  margin-left: -16px;

  ${media.tablet`
    margin-left: -20px;
  `}
`;

export const Description = styled.p`
  ${text};
  color: ${colors.white};
  text-shadow: 0 2px 16px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 1.67;
  max-width: 408px;
  
  ${media.tablet`
    margin-top: -4px;
  `};
`;

export const LineDecoration = styled(LineDecorationStyle)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  
  ${media.tablet`
    display: none;
  `}
  
  ${media.tabletLarge`
    display: block;
    bottom: 100px;
    
    ${Line} {
      &:after { top: 93px; };
    }
  `}
  
  ${media.desktop`
    bottom: 126px;

    ${Line} {
      height: 119px;
      
      &:after {
        top: 119px;
      }
    }
  `}
  
  ${media.desktopFull`
    bottom: 184px;

    ${Line} {
      height: 177px;
      
      &:after {
        top: 177px;
      }
    }
  `}
`;
