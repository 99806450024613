import styled, { css } from 'styled-components';
import { Container as ContainerComponent } from '../../theme/layout';
import { text } from '../../theme/typography';
import { colors } from '../../theme/styled';
import { media, sizes } from '../../theme/media';
import { Image as ResponsiveImage } from '../image/image.component';
import { Link } from '../link/link.component';

const CHAR_LIMIT = 21;
const CHAR_LIMIT_DESKTOP = 28;
const CHAR_LIMIT_WRAP = 19;

export const Container = styled(ContainerComponent)`
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  position: relative;
`;

export const IMAGE_SIZES = {
  [sizes.mobile]: 32,
  [sizes.desktop]: 36,
};

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled(Link)`
  align-items: center;
  display: flex;
  &:not:last-of-type {
    margin-bottom: 0.3em;
  }
`;

export const AvatarContainer = styled.div`
  align-items: center;
  display: flex;
  margin-right: 18px;
  min-width: ${props => props.count * 23 + 20}px;
  position: relative;
  ${props =>
    props.count > 1 &&
    css`
      img {
        border: 2px solid ${colors.white};
      }
    `}
`;

export const Image = styled(ResponsiveImage)`
  width: 32px;
  margin-right: 10px;
  border-radius: 100%;
  position: ${props => props.stacked && 'absolute'};
  z-index: 10;

  ${[2, 3, 4].map(
    i => css`
      &:nth-of-type(${i}) {
        left: ${23 * (i - 1)}px;
        z-index: ${10 - i};

        & + :not(img) {
          margin-left: ${15 * (i - 1)}px;
        }
      }
    `,
  )}

  ${media.desktop`
    width: 36px;
  `};
`;

export const textStyles = css`
  ${text};
  font-size: 12px;
  ${media.desktop`
    font-size: 14px;
  `};
`;

export const Text = styled.address`
  ${textStyles};
  line-height: 17px;
  flex: ${props => (props.length > CHAR_LIMIT_WRAP ? 0 : 'none')};

  ${media.mobile`
    flex: ${props => (props.length > CHAR_LIMIT ? 0 : 'none')};
  `};

  ${media.desktop`
    flex: ${props =>
    props.length > CHAR_LIMIT_DESKTOP || (props.forceWrap && props.length > CHAR_LIMIT_WRAP)
      ? 0
      : 'none'};
  `};
`;

export const Separator = styled.div`
  border-left: 1px solid ${colors.yellow};
  width: 0;
  height: ${props => (props.count || 0 + 1) * 12}px;
  margin: 0 12px;
`;

export const DateContainer = styled.div`
  ${textStyles};
  color: ${colors.white};
  flex: 1;
`;

export const Date = styled.time`
  white-space: nowrap;

  ${props =>
    props.isRevised &&
    css`
      opacity: 0.6;
    `}
`;
