import styled from 'styled-components';
import { colors } from '../../theme/styled';
import { Link } from '../../components/link/link.component';
import { title, text } from '../../theme/typography';
import { LinkList as LinkListComponent } from '../../components/linkList/linkList.component';
import { SocialIcons as SocialIconsComponent } from '../../components/socialIcons/socialIcons.component';
import {
  Title as SocialIconsTitle,
  IconContainer as SocialIconContainer,
  IconListItem as SocialIconListItem,
} from '../../components/socialIcons/socialIcons.styles';
import { media } from '../../theme/media';

export const TRANSITION_DURATION = 0.5;

export const Container = styled.div`
  visibility: ${props => (props.shouldDisplay ? 'visible' : 'hidden')};
  position: fixed;
  transform: translateX(${props => (props.isOpened ? '-100%' : 0)});
  left: 100%;
  top: ${p => p.offset}px;
  bottom: 0;
  height: calc(100vh - ${p => p.offset}px);
  width: 100vw;
  background-color: ${colors.darkGrey};
  transition: transform ${TRANSITION_DURATION}s ease-in-out;
  z-index: 5;
  overflow-y: auto;

  ${media.tabletLarge`
    width: 600px;
    transform: translateX(${props => (props.isOpened ? '-600px' : 0)});
  `};
`;

export const FeaturedLink = styled(Link)`
  ${title};
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72px;
  background: ${props => (props.useSecondaryColor ? colors.yellow : colors.accessibilityRed)};
  color: ${props => (props.useSecondaryColor ? colors.darkGrey : '')};
  font-size: 14px;
`;

export const FormContainer = styled.div`
  display: ${props => (props.shouldDisplay ? 'block' : 'none')};
  padding: 32px 24px 0;
  margin-bottom: 26px;

  iframe {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const LinkList = styled(LinkListComponent)`
  nav {
    width: 100%;
    border-top: 1px solid ${colors.borderMenuItem};
    padding: 0 24px;
  }

  li {
    width: 100%;
    border-top: 1px solid ${colors.borderMenuItem};

    &:first-child {
      border-top: none;
    }
  }

  a {
    ${text};
    width: 100%;
    color: ${colors.white};
    display: block;
    font-size: 14px;
    line-height: 1.57;
    padding-top: 19px;
    padding-bottom: 19px;
  }
`;

export const TermsList = styled(LinkList)`
  background-color: ${colors.black};
`;

export const SocialIcons = styled(SocialIconsComponent)`
  justify-content: center;
  background-color: ${colors.black};
  flex-direction: column;
  padding-bottom: 32px;

  ${SocialIconsTitle} {
    ${title};
    color: ${colors.white};
    font-size: 14px;
    letter-spacing: 2px;
    text-align: center;
    margin: 23px 0 20px;
  }

  ${SocialIconContainer} {
    width: 28px;
    height: 28px;
  }

  ${SocialIconListItem} {
    margin-left: 16px;

    &:first-child {
      margin-left: 0;
    }
  }
`;
